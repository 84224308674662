<div *ngIf="!preview && !browserSupport.isWebkitDirSupported" id="compatibility-warning">
  <h3>Note: your browser does not support uploading directories</h3>
  <h3>Kompakkt (and the content you want to upload) works best on the latest web technologies</h3>
  <h3>Please consider switching to a different browser!</h3>
</div>

<div id="fileFormats" *ngIf="!preview">
  <div class="file-format-line" [class.disabled]="(isDetermined$ | async) && !(isModel$ | async)">
    <div>
      <mat-icon>language</mat-icon>
      <span>3D models</span>
    </div>
    <span>
      <b
        matTooltip="Recommended as this format can fit meshes, textures and materials inside of a single file. Best compression"
        >.glb</b
      >,
      <b
        matTooltip="Recommended for point clouds. The files will be processed on the server to be compatible with the viewer"
        >.las/.laz</b
      >,
      <i
        matTooltip="For best results consider converting your model to one of the recommended formats"
        >.gltf, .obj, .stl</i
      >
    </span>
  </div>

  <div class="file-format-line" [class.disabled]="(isDetermined$ | async) && !(isImage$ | async)">
    <div>
      <mat-icon>image</mat-icon>
      <span>Images</span>
    </div>
    <span> <b matTooltip="Recommended"> .jpg, .png </b>, .tga, .gif, .bmp </span>
  </div>

  <div class="file-format-line" [class.disabled]="(isDetermined$ | async) && !(isAudio$ | async)">
    <div>
      <mat-icon>audiotrack</mat-icon>
      <span>Audio</span>
    </div>
    <span>
      <b matTooltip="Recommended and supported by most browsers"> .mp3, .wav </b>,
      <i
        matTooltip="Strongly dependent on the browser. For best results consider converting your model to one of the recommended formats"
      >
        .ogg, .m4a
      </i>
    </span>
  </div>

  <div class="file-format-line" [class.disabled]="(isDetermined$ | async) && !(isVideo$ | async)">
    <div>
      <mat-icon>movie</mat-icon>
      <span>Video</span>
    </div>
    <span>
      <b matTooltip="Recommended and supported by most browsers">.mp4</b>,
      <i
        matTooltip="Strongly dependent on the browser. For best results consider converting your model to one of the recommended formats"
      >
        .webm, .ogv
      </i>
    </span>
  </div>
</div>

<div id="uploadButton" [class.disabled]="disableFileInput$ | async" *ngIf="!preview">
  <div *ngIf="browserSupport.isWebkitDirSupported">
    <h3>Upload a folder</h3>
    <p>
      If you want to upload a 3D model with additional files (e.g. textures or materials), please
      select the folder containing <b>all</b> necessary files
    </p>
    <p>
      If you want to make your content available in multiple quality levels, you can store them all
      in the same folder and upload them here
    </p>
    <button mat-raised-button (click)="fileInput.click()">Choose a folder</button>
    <input
      id="directoryupload"
      hidden
      #fileInput
      type="file"
      webkitdirectory
      multiple
      (change)="handleFileInput(fileInput)"
    />
  </div>
  <div>
    <h3>Upload single or multiple files</h3>
    <p>
      Images, audio files, video files or any single file 3D models (e.g. GLB or BABYLON files) are
      best uploaded using only the single file
    </p>
    <p>
      If your content is available in several qualities or is spread over several files, please use
      the folder option instead
    </p>
    <button mat-raised-button (click)="fileInput.click()">Choose files</button>
    <input
      id="fileupload"
      hidden
      #fileInput
      type="file"
      multiple
      (change)="handleFileInput(fileInput)"
    />
  </div>
</div>

<div id="mediaTypePreview" *ngIf="preview">
  <ng-container *ngIf="mediaType$ | async as mediaType">
    <h4>Mediatype: {{ mediaTypeTexts[mediaType] }}</h4>
    <mat-icon>{{ mediaTypeIcons[mediaType] }}</mat-icon>
  </ng-container>
</div>

<ng-container *ngIf="queue$ | async as queue">
  <h3>Queued files</h3>

  <table
    mat-table
    id="uploadTable"
    [dataSource]="queue"
    *ngIf="queue.length !== 0; else noItemsQueued"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef>Size</th>
      <td mat-cell *matCellDef="let element">{{ (element.size / 1024 / 1024).toFixed(2) }}MB</td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef>Progress</th>
      <td mat-cell *matCellDef="let element">{{ element.progress }}%</td>
    </ng-container>

    <ng-container matColumnDef="processing">
      <th mat-header-cell [hidden]="!(isCloud$ | async)" *matHeaderCellDef>Processing</th>
      <td mat-cell [hidden]="!(isCloud$ | async)" *matCellDef="let element">
        {{ element.processing }}%
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <ng-template #noItemsQueued>
    <p>No items in queue</p>
  </ng-template>
</ng-container>
<!-- warning if element.size is over 1GB -->
<ng-container *ngIf="queue$ | async as queue">
  <ng-container *ngIf="queue.length !== 0">
    <ng-container *ngFor="let element of queue">
      <ng-container *ngIf="element.size > maxFilesize">
        <div class="warning">
          <mat-icon>warning</mat-icon>
          <span>
            <strong>{{ element.name }}</strong> is over {{ maxFilesize / 1024 / 1024 }} MB in size.
            This may cause issues with uploading and downloading. Please consider splitting your
            content into multiple files.
          </span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
