import { Pipe, PipeTransform } from '@angular/core';
import { IWikibaseItem } from '~common/interfaces';

@Pipe({
  name: 'getLabel',
})
export class GetLabelPipe implements PipeTransform {
  public transform(value: string | IWikibaseItem | undefined) {
    if (!value) {
      return 'No label';
    }
    if (typeof value === 'string') {
      return value;
    }
    return value.label.en;
  }
}
