<footer>
  <nav id="footerlinks">
    <ul>
      <li><span>&copy; 2021-{{currentYear}} Semantic Kompakkt</span>
      </li>
      <li>
        <a href="https://gitlab.com/nfdi4culture/ta1-data-enrichment/semantic-kompakkt">
          <img class="footer-icon" src="assets/icons/GitLab-Mark-32px.png" alt="gitlab.com Homepage" />
        </a>
      </li>
      <li><a routerLink="about">About</a></li>
      <li><a href="https://www.tib.eu/en/imprint">Imprint</a>
      </li>
      <li><a routerLink="privacy">Privacy Policy</a></li>
      <li><a routerLink="consortium">Consortium</a></li>
      <li *ngIf="workshopMode" ngClass="workshop">
        <mat-icon>settings</mat-icon>
        We are currently in workshop mode.
      </li>
    </ul>
  </nav>
</footer>