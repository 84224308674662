import { Pipe, PipeTransform } from '@angular/core';
import { IWikibaseItem } from '~common/interfaces';

@Pipe({
  name: 'getWikibaseProp',
})
export class GetWikibaseProp implements PipeTransform {
  public transform(value: string | IWikibaseItem | undefined, prop: string) {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    if (value[prop] === undefined) {
      return '';
    }
    return value[prop].toString();
  }
}
